import { ReactNode } from 'react';
import './body-layout.scss';

type BodyLayoutProps = {
  children: ReactNode
}
const BodyLayout = (props: BodyLayoutProps) => {
  return (
    <div className='body-layout-main'>
      {props.children}
    </div>
  );
};
export default BodyLayout;