import './formule.scss';
import BodyLayout from '../../components/layout/body-layout/body-layout';
import { useEffect } from 'react';

const Formule = () => {
  useEffect(() => {
    document.title = 'Saint lazare | Formule';
  }, []);
  return (
    <BodyLayout>
      <div className='formule formulePage'></div>
    </BodyLayout>
  );
};
export default Formule;