import './layout.scss';
import Header from '../header/header';
import Footer from '../footer/footer';
import React, { ReactNode, useState } from 'react';
import PanelHamburger from '../panels/panel-hamburger/panel-hamburger';

type LayoutProps = {
  children: ReactNode
}
const Layout = (props: LayoutProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <div className='layout-main'>
      <Header isOpen={isOpen} setOpen={setOpen} />
      <PanelHamburger isOpen={isOpen} setIsOpen={setOpen} />
      {props.children}
      <Footer />
    </div>
  );
};
export default Layout;