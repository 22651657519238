export const returnLocation = (l: any) => {
  switch (l.pathname) {
    case '/':
      return 'home';
    case '/contact':
      return 'contact';
    case '/formule':
      return 'formule';
    case '/info':
      return 'infos';
  }
};