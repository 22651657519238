import './header.scss';
import { Link, useLocation } from 'react-router-dom';
import { returnLocation } from '../../utils/methods/returnLocation';
import { NavePath } from '../../domain/custom-domain';
import { navList } from './navList/nav-list';
import { Spiral as Hamburger } from 'hamburger-react';
import { Dispatch, SetStateAction } from 'react';

type HeaderProps = {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}
const Header = ({ isOpen, setOpen }: HeaderProps) => {
  const l = useLocation();
  return (
    <header className={returnLocation(l)}>

      <div className='h1'>Auto ecole saint lazare</div>
      <ul>
        {navList(l).map((n: NavePath, i: number) => {
          return <li key={i}><Link to={n.path} className={n.classname}>{n.label}</Link></li>;
        })}
      </ul>
      <div className={`hamburger ${returnLocation(l)}`}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
    </header>
  );
};
export default Header;