import './home.scss';
import BodyLayout from '../../components/layout/body-layout/body-layout';
import { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    document.title = 'Saint lazare | Accueil';
  }, []);
  return (
    <BodyLayout>
      <div className='homepage home'>
      </div>
    </BodyLayout>
  );
};
export default Home;