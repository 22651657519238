import './footer.scss';
import { useLocation } from 'react-router-dom';
import { returnLocation } from '../../utils/methods/returnLocation';

const Footer = () => {
  const l = useLocation();
  return (
    <footer className={returnLocation(l)}>© Auto ecole saint lazare - {"{ ön-air }"} - {new Date().getFullYear()}</footer>
  );
};
export default Footer;