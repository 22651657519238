import './contact.scss';
import BodyLayout from '../../components/layout/body-layout/body-layout';
import { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    document.title = 'Saint lazare | Contact';
  }, []);
  return (
    <BodyLayout>
      <div className='contactpage contact'>

      </div>
    </BodyLayout>
  );
};
export default Contact;