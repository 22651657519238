import { returnLocation } from '../../../utils/methods/returnLocation';
import { NavePath } from '../../../domain/custom-domain';
export const navList = (l: any): NavePath[] => {
  return [
    {
      path: '/',
      classname: returnLocation(l),
      label: 'Accueil'
    },
    {
      path: '/formule',
      classname: returnLocation(l),
      label: 'Formule'
    },
    {
      path: '/info',
      classname: returnLocation(l),
      label: 'Info'
    },
    {
      path: '/contact',
      classname: returnLocation(l),
      label: 'Contact'
    }
  ];
};