import './panel-hamburger.scss';
import { navList } from '../../header/navList/nav-list';
import { NavePath } from '../../../domain/custom-domain';
import { Link, useLocation } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';

type PanelHamburgerProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}
const PanelHamburger = ({ isOpen, setIsOpen }: PanelHamburgerProps) => {
  const l = useLocation();
  return (
    <div className={isOpen ? 'panel-h-main open' : ' panel-h-main close'}>
      {navList(l).map((n: NavePath, i: number) => {
        return <span onClick={() => setIsOpen(false)} key={i}><Link to={n.path} className={n.classname}>{n.label}</Link></span>;
      })}
    </div>
  );
};
export default PanelHamburger;