import './infos.scss';
import BodyLayout from '../../components/layout/body-layout/body-layout';
import { useEffect } from 'react';

const Infos = () => {
  useEffect(() => {
    document.title = 'Saint lazare | Infos';
  }, []);
  return (
    <BodyLayout>
      <div className='infos infoPage'>
      </div>
    </BodyLayout>
  );
};
export default Infos;