import Home from '../pages/home/home';
import Contact from '../pages/contact/contact';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Formule from '../pages/formule/formule';
import Infos from '../pages/infos/infos';

const PRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/formule' element={<Formule />} />
      <Route path='/info' element={<Infos />} />
      <Route path='/contact' element={<Contact />} />
    </Routes>
  );
};
export default PRoutes;